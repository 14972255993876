import styled from '@emotion/styled';
import { SwipeableDrawer } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import bluePlanetTheme, { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import { contentSpacing, halfSpacing, quarterSpacing } from 'ui/theme/themeConstants';

const DrawerList = styled.div`
  padding: ${halfSpacing} 0;
  max-height: 90vh;
`;

const DrawerItemButton = styled.button`
  text-align: left;
  min-height: 60px;
  font-size: ${fontSizeSmall};
  font-weight: 500;
  padding: ${halfSpacing} ${contentSpacing};
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${bluePlanetTheme.bluePlanetPalette.grey.light};
  }
`;

export const DrawerItemText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const HelperText = styled.div`
  font-size: ${fontSizeSmall};
  font-weight: 400;
  margin-top: ${quarterSpacing};
`;

export function DrawerItem({
  children,
  onClick,
  className,
  innerClassName,
  prefix,
  disabled,
  helperText,
  suffix,
  color,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  innerClassName?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  disabled?: boolean;
  title?: string;
  helperText?: string;
  color?: string;
}) {
  return (
    <DrawerItemButton onClick={onClick} disabled={disabled} className={classNames(className, 'u-flex-center')}>
      <div style={{ color: color }} className={classNames('u-flex u-flex-align-center', innerClassName)}>
        {prefix && <span className="u-half-spacing-right">{prefix}</span>}
        <DrawerItemText>{children}</DrawerItemText>
        {suffix && <span className="u-half-spacing-left">{suffix}</span>}
      </div>
      {helperText && <HelperText>{helperText}</HelperText>}
    </DrawerItemButton>
  );
}

export default function DrawerBottom({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <SwipeableDrawer
      sx={{ zIndex: 1400 }} // zIndex must be higher than dialog zIndex
      disableSwipeToOpen
      onOpen={() => {}}
      onClose={onClose}
      anchor="bottom"
      open={isOpen}
    >
      <DrawerList>{children}</DrawerList>
    </SwipeableDrawer>
  );
}
