import config from 'config';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDialogHandler, { DialogHandlerProps } from 'hooks/useDialogHandler';
import useJourneyLogger from 'hooks/useJourneyLogger';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { ICompany } from 'types/company';
import Button from 'ui/elements/buttons/Button';
import MenuButton, { ButtonMenu } from 'ui/elements/buttons/MenuButton';
import Chip from 'ui/elements/Chip';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import QrCodeIcon from 'ui/elements/icons/QrCodeIcon';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import Accordion from 'ui/modules/Accordion';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { companyUrls } from 'urls';
import Box from '@mui/material/Box';
import DownloadIcon from 'ui/elements/icons/DownloadIcon';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { NavigationItem } from 'ui/elements/BottomNavigation';
import DrawerBottom, { DrawerItem } from 'ui/elements/DrawerBottom';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import SendIcon from 'ui/elements/icons/SendIcon';
import { getIframeCode } from 'pages/CompanySettings/utils';
import CodeIcon from 'ui/elements/icons/CodeIcon';

export default function ShareCompany({ company, canEditCompany }: { company: ICompany; canEditCompany: boolean }) {
  const shareCompanyDialogHandler = useDialogHandler();
  const qrCodeDialogHandler = useDialogHandler();
  const [log] = useJourneyLogger();

  const {
    copy: copyProfileLink,
    showCopiedSuccess: showProfileLinkCopiedSuccess,
    clear: clearProfileLinkCopiedSuccess,
  } = useCopyToClipboard(
    `${location.origin}${companyUrls.overview(
      company.slug,
      'profile',
    )}?utm_source=direct&utm_medium=copy-link&utm_term=company&utm_campaign=profile`,
  );
  const {
    copy: copyEmbedCode,
    showCopiedSuccess: showEmbedCodeCopiedSuccess,
    clear: clearEmbedCodeCopiedSuccess,
  } = useCopyToClipboard(getIframeCode(company.slug, 'detailed'));

  const { push } = useHistory();

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const [showMenu, setShowMenu] = useState(false);

  if (isMobile) {
    return (
      <>
        <NavigationItem onClick={() => setShowMenu(true)}>
          <ShareIcon color="indigo" />
          Share
        </NavigationItem>
        <DrawerBottom onClose={() => setShowMenu(false)} isOpen={showMenu}>
          <DrawerItem
            prefix={<QrCodeIcon />}
            className="data-track-show-company-qr-code"
            onClick={() => {
              setShowMenu(false);
              qrCodeDialogHandler.open();
            }}
          >
            Show QR code
          </DrawerItem>
          <DrawerItem
            prefix={<SendIcon />}
            className="data-track-share-company-in-chat"
            onClick={() => {
              setShowMenu(false);
              log('share company in chat');
              shareCompanyDialogHandler.open();
            }}
          >
            Send in chat
          </DrawerItem>
          <DrawerItem
            prefix={<LinkIcon />}
            suffix={showProfileLinkCopiedSuccess ? <Chip label="Copied" color="green" /> : undefined}
            onClick={() => {
              log('copied link to company profile');
              clearEmbedCodeCopiedSuccess();
              copyProfileLink();
            }}
            className="data-track-share-company-link"
          >
            Copy link to profile
          </DrawerItem>
          <DrawerItem
            prefix={<CodeIcon />}
            suffix={showEmbedCodeCopiedSuccess ? <Chip label="Copied" color="green" /> : undefined}
            onClick={() => {
              log('copied company embed code');
              clearProfileLinkCopiedSuccess();
              copyEmbedCode();
            }}
            className="data-track-share-company-embed"
          >
            Copy embed code
          </DrawerItem>
          {canEditCompany && (
            <DrawerItem
              prefix={<SettingsIcon />}
              className="data-track-show-all-share-tools"
              onClick={() => {
                push(companyUrls.settings.share(company.slug));
              }}
            >
              Show all share tools
            </DrawerItem>
          )}
        </DrawerBottom>
        {qrCodeDialogHandler.isOpen && (
          <QrCodeDialog
            companyName={company.name}
            url={`${config.PORTAL_URL}/companies/${company.slug}`}
            handler={qrCodeDialogHandler}
            filename={company.name}
          />
        )}
        {shareCompanyDialogHandler.isOpen && (
          <UserConversationDialog
            sharedContent={{ type: 'company', companyId: company.id }}
            conversation={{ type: 'new' }}
            closeConversation={shareCompanyDialogHandler.close}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Accordion
        className="data-track-share-company-accordion"
        padding="header-only"
        summaryBackgroundColor="blue-very-light"
        accordionBackgroundColor="transparent"
        fontSize="small"
        title={
          <>
            <ShareIcon className="u-half-spacing-right" />
            Share company
          </>
        }
      >
        <div style={{ marginLeft: '1rem' }}>
          <ButtonMenu>
            <MenuButton
              className="data-track-show-company-qr-code"
              onClick={() => {
                qrCodeDialogHandler.open();
              }}
            >
              <QrCodeIcon className="u-half-spacing-right" />
              Show QR code
            </MenuButton>
            <MenuButton
              className="data-track-share-company-in-chat"
              onClick={() => {
                log('share company in chat');
                shareCompanyDialogHandler.open();
              }}
            >
              <SendIcon className="u-half-spacing-right" />
              Send in chat
            </MenuButton>
            <MenuButton
              onClick={() => {
                log('copied link to company profile');
                clearEmbedCodeCopiedSuccess();
                copyProfileLink();
              }}
              className="data-track-share-company-link"
            >
              <LinkIcon className="u-half-spacing-right" /> Copy link to profile
              {showProfileLinkCopiedSuccess && <Chip className="u-margin-left-auto" label="Copied" color="green" />}
            </MenuButton>
            <MenuButton
              onClick={() => {
                log('copied company embed code');
                clearProfileLinkCopiedSuccess();
                copyEmbedCode();
              }}
              className="data-track-share-company-embed"
            >
              <CodeIcon className="u-half-spacing-right" /> Copy embed code
              {showEmbedCodeCopiedSuccess && <Chip className="u-margin-left-auto" label="Copied" color="green" />}
            </MenuButton>
            {canEditCompany && (
              <MenuButton
                className="data-track-show-all-share-tools"
                onClick={() => {
                  push(companyUrls.settings.share(company.slug));
                }}
              >
                <SettingsIcon className="u-half-spacing-right" />
                Show all share tools
              </MenuButton>
            )}
          </ButtonMenu>
        </div>
      </Accordion>
      {qrCodeDialogHandler.isOpen && (
        <QrCodeDialog
          companyName={company.name}
          url={`${config.PORTAL_URL}/companies/${company.slug}`}
          handler={qrCodeDialogHandler}
          filename={company.name}
        />
      )}
      {shareCompanyDialogHandler.isOpen && (
        <UserConversationDialog
          sharedContent={{ type: 'company', companyId: company.id }}
          conversation={{ type: 'new' }}
          closeConversation={shareCompanyDialogHandler.close}
        />
      )}
    </>
  );
}

export function QrCodeDialog({
  companyName,
  url,
  filename,
  handler,
}: {
  companyName: string;
  url: string;
  filename: string;
  handler: DialogHandlerProps;
}) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const qrCanvasRef = useRef<HTMLDivElement>(null);

  const downloadQrCode = () => {
    if (!qrCanvasRef.current) {
      return;
    }

    const canvas = qrCanvasRef.current.children[0] as HTMLCanvasElement;
    if (!canvas) {
      return;
    }

    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = `${filename} - Qr Code`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  return (
    <Dialog open={handler.isOpen} onClose={handler.close} fullScreen={isMobile}>
      <Title onClose={handler.close}>Scan to connect with {companyName}</Title>
      <Content></Content>
      <Box ref={qrCanvasRef} sx={{ padding: 2, mx: 'auto', width: { sm: 1 / 2 } }}>
        <QRCodeCanvas size={300} value={url} style={{ width: '100%', height: 'auto' }} />
      </Box>
      <DialogActions align="space-between">
        <Button kind="tertiary" onClick={handler.close} color="grey">
          Close
        </Button>
        <Button onClick={downloadQrCode} kind="tertiary" color="grey">
          <DownloadIcon color="grey" fontSize="small" className="u-quarter-spacing-right" />
          Download QR code
        </Button>
      </DialogActions>
    </Dialog>
  );
}
