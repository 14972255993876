import EditIcon from 'ui/elements/icons/EditIcon';
import React, { useState } from 'react';
import { Update } from 'types/content';
import Chip from 'ui/elements/Chip';
import ArchiveIcon from 'ui/elements/icons/ArchiveIcon';
import UnarchiveIcon from 'ui/elements/icons/UnarchiveIcon';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import Button from 'ui/elements/buttons/Button';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import IconButton from 'ui/elements/icons/IconButton';
import useBackoffice from 'hooks/useBackoffice';
import GraphIcon from 'ui/elements/icons/GraphIcon';
import { bluePlanetTheme } from 'ui/theme';
import UpdateStatsDialog from '../UpdateStatsDialog';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import { Auth0User } from 'types';
import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from 'auth/util';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';

function UpdateStatsMenuItem({ handleClose, updateId }: { updateId: string; handleClose: () => void }) {
  const [updateStatsDialogIsOpen, setUpdateStatsDialogIsOpen] = useState(false);
  const onClose = () => {
    handleClose();
    setUpdateStatsDialogIsOpen(false);
  };

  const backoffice = useBackoffice();
  return (
    <>
      {backoffice.adminRights.includes('read-everything') && backoffice.showBackofficeInfoOnProfiles ? (
        <MenuItem
          onClick={() => setUpdateStatsDialogIsOpen(true)}
          sx={{ backgroundColor: bluePlanetTheme.bluePlanetPalette.yellow.medium }}
        >
          <ListItemIcon>
            <GraphIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>View stats</ListItemText>
        </MenuItem>
      ) : null}
      {updateStatsDialogIsOpen && <UpdateStatsDialog updateId={updateId} onClose={onClose} />}
    </>
  );
}

interface Props {
  update: Update;
  api: UpdateAPI;
  onEdit?: () => void;
  canEditPost?: boolean;
  onArchive?: (contentId: string) => void;
  onDelete?: () => void;
}

export default function UserUpdateMenu(props: Props) {
  const { update } = props;
  const [isDeleting, setDeleting] = useState(false);

  const [isArchived, setArchived] = useState<boolean>(update.archived || false);
  const notify = useNotify();

  const deleteDialogHandler = useDialogHandler();

  const [onArchive] = useLazyResource(
    (isArchived: boolean) => (!isArchived ? props.api.archive(props.update.id) : props.api.unArchive(props.update.id)),
    {
      onSuccess: () => {
        setArchived(!isArchived);
        notify('success', `Update has been ${isArchived ? 'un' : ''}archived`);
      },
      onFailure: () => notify('error', `Could not ${isArchived ? 'un' : ''}archive update. Please try again later.`),
    },
  );

  const onDeleteContent = async () => {
    try {
      setDeleting(true);
      await props.api.delete(props.update.id);
      props.onDelete && props.onDelete();
      notify('success', 'Update deleted');
    } catch {
      setDeleting(false);
      notify('error', 'Failed to delete update');
    } finally {
      setDeleting(false);
      deleteDialogHandler.close();
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const shouldDisplayActions = props.canEditPost;
  if (!shouldDisplayActions) {
    return null;
  }

  return (
    <>
      {isArchived && <Chip color="orange-light" label="Archived" className="u-quarter-spacing-left" />}
      <IconButton color="indigo" onClick={handleOpen}>
        <EllipsisVertical />
      </IconButton>
      <Menu
        disableAutoFocusItem // needed to make the MUI menu keyboard navigation work
        MenuListProps={{ style: { minWidth: '210px' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {props.canEditPost && props.onEdit && (
          <MenuItem
            onClick={() => {
              handleClose();
              if (props.onEdit) props.onEdit();
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {props.canEditPost && (
          <MenuItem onClick={() => onArchive(isArchived)}>
            <ListItemIcon>
              {isArchived ? <UnarchiveIcon fontSize="medium" /> : <ArchiveIcon fontSize="medium" />}
            </ListItemIcon>
            <ListItemText>{isArchived ? 'Unarchive' : 'Archive'}</ListItemText>
          </MenuItem>
        )}
        {props.onDelete && canAccessBackoffice && (
          <MenuItem onClick={deleteDialogHandler.open}>
            <ListItemIcon>
              <TrashIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
        <UpdateStatsMenuItem updateId={props.update.id} handleClose={handleClose} />
      </Menu>
      <ConfirmDialog
        isOpen={deleteDialogHandler.isOpen}
        onClose={deleteDialogHandler.close}
        title="You are about to delete this post"
        confirmButton={
          <Button color="red" kind="primary" onClick={onDeleteContent} isLoading={isDeleting}>
            Yes, delete
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <p className="u-content-spacing-bottom">It will no longer be visible anywhere on the platform.</p>
        <p>Are you sure?</p>
      </ConfirmDialog>
    </>
  );
}
